import { __decorate, __extends } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import CardVideoBimMeet from '@/components/common/CardVideoBimMeet.vue';
var RegisterHistories = (function (_super) {
    __extends(RegisterHistories, _super);
    function RegisterHistories() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = false;
        return _this;
    }
    __decorate([
        Prop({ default: false })
    ], RegisterHistories.prototype, "loading", void 0);
    RegisterHistories = __decorate([
        Component({
            components: { CardVideoBimMeet: CardVideoBimMeet }
        })
    ], RegisterHistories);
    return RegisterHistories;
}(Vue));
export default RegisterHistories;
