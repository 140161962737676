var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "register__container" },
    [
      _c(
        "p",
        {
          staticClass: "register__back",
          on: {
            click: function($event) {
              return _vm.$router.go(-1)
            }
          }
        },
        [_c("BIcon", { attrs: { icon: "chevron-left" } }), _vm._v(" Back ")],
        1
      ),
      _c("img", {
        staticClass: "register__logo",
        attrs: {
          src: require("../../assets/images/logo-dark.svg"),
          alt: "BeemMeet"
        }
      }),
      _c("h1", { staticClass: "register__title" }, [
        _vm._v("Sign up for a free account!")
      ]),
      _c(
        "FormulateForm",
        {
          ref: "registerForm",
          staticClass: "register__form",
          on: { submit: _vm.register },
          model: {
            value: _vm.formValues,
            callback: function($$v) {
              _vm.formValues = $$v
            },
            expression: "formValues"
          }
        },
        [
          _vm.error
            ? _c("BMessage", { attrs: { type: "is-danger" } }, [
                _c("p", { staticClass: "is-size-7" }, [
                  _vm._v(_vm._s(_vm.error.message))
                ])
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "group__row" },
            [
              _c("FormulateInput", {
                attrs: {
                  name: "name",
                  type: "text",
                  placeholder: "Name",
                  validation: "alpha:default"
                }
              }),
              _c("FormulateInput", {
                attrs: {
                  name: "lastname",
                  type: "text",
                  placeholder: "Lastname",
                  validation: "alpha:default"
                }
              })
            ],
            1
          ),
          _c("FormulateInput", {
            staticClass: "register__email",
            attrs: {
              name: "email",
              type: "email",
              placeholder: "Email address",
              validation: "required|email"
            }
          }),
          _c(
            "div",
            { staticClass: "group__row" },
            [
              _c("FormulateInput", {
                attrs: {
                  name: "password",
                  type: "password",
                  placeholder: "Password",
                  validation: "min:6"
                }
              }),
              _c("FormulateInput", {
                attrs: {
                  type: "tel",
                  name: "phoneNumber",
                  placeholder: "Phone number",
                  validation: [
                    [
                      "matches",
                      /(\+34|0034|34)?[ -]*(6|7|9)[ -]*([0-9][ -]*){8}/
                    ]
                  ],
                  "validation-messages": {
                    matches: "Must provide a valid number"
                  }
                }
              })
            ],
            1
          ),
          _c(
            "VBtn",
            { staticClass: "register__button", attrs: { type: "submit" } },
            [_vm._v("GET STARTED")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }