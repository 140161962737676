import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Hero from '@/components/bulma/Hero.vue';
import RegisterForm from '@/components/auth/RegisterForm.vue';
import RegisterHistories from '@/components/auth/RegisterHistories.vue';
var RegisterPage = (function (_super) {
    __extends(RegisterPage, _super);
    function RegisterPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RegisterPage = __decorate([
        Component({
            components: { Hero: Hero, RegisterForm: RegisterForm, RegisterHistories: RegisterHistories }
        })
    ], RegisterPage);
    return RegisterPage;
}(Vue));
export default RegisterPage;
