import { __assign, __awaiter, __decorate, __extends, __generator } from "tslib";
import { Vue, Watch, Component } from 'vue-property-decorator';
var RegisterForm = (function (_super) {
    __extends(RegisterForm, _super);
    function RegisterForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = false;
        _this.error = '';
        _this.formValues = {};
        _this.valid = true;
        return _this;
    }
    RegisterForm.prototype.hideError = function () {
        var _this = this;
        setTimeout(function () { return (_this.error = null); }, 7000);
    };
    RegisterForm.prototype.register = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, password, email, userData, create, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.loading = true;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        _a = this.formValues, password = _a.password, email = _a.email;
                        userData = __assign(__assign({}, this.formValues), { displayName: this.displayName });
                        delete userData.password;
                        localStorage.setItem('userData', JSON.stringify(userData));
                        return [4, this.$auth.createUserWithEmailAndPassword(email, password)];
                    case 2:
                        create = _b.sent();
                        this.loading = false;
                        return [3, 4];
                    case 3:
                        error_1 = _b.sent();
                        console.log('register ~ error', error_1.message);
                        this.error = error_1;
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    Object.defineProperty(RegisterForm.prototype, "displayName", {
        get: function () {
            return this.formValues.name + ' ' + this.formValues.lastname;
        },
        enumerable: false,
        configurable: true
    });
    RegisterForm.prototype.sendVerificationMail = function () {
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.$functions.httpsCallable('sendVerificationEmail')(window.location.href)];
                    case 1:
                        resp = _a.sent();
                        console.log('sendVerificationMail -> resp', resp);
                        return [2];
                }
            });
        });
    };
    __decorate([
        Watch('error')
    ], RegisterForm.prototype, "hideError", null);
    RegisterForm = __decorate([
        Component
    ], RegisterForm);
    return RegisterForm;
}(Vue));
export default RegisterForm;
